<script>
import sort from "fast-sort";

export default {
  components: {},
  data() {
    return {
      searchValue: "",
      customers: [],
      currentPage: 1,
      perPage: 10,
      totalItems: 1,
      fields: [
        {
          key: 'company_name',
          label: 'Company Name',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'account_id',
          label: 'Account Id',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'plan',
          label: 'Plan',
        },
        {
          key: 'total_proofs',
          label: 'Total Proofs',
        },
        {
          key: 'date',
          label: 'Date Joined',
        },
        {
          key: 'createdAt',
          label: 'Date Account Joined',
        },
        {
          key: 'date_last_login',
          label: 'Date Of Last Login',
        },
        {
          key: 'last_ip',
          label: 'Last Ip',
        },
        {
          key: 'impersonate',
          label: ''
        }
      ],
    };
  },
  async mounted() {
    let self = this;
    await this.loadPage(this.currentPage, this.perPage);
    this.$A.viewToViewData = null;
    this.$root.$on("page::search::value::updated", (searchValue) => {
      self.searchValue = searchValue;
    });
  },
  computed: {
    // filteredCustomers: function () {
    //   let self = this;
    //   return this.customers.filter(function (customer) {
    //     let searchValueLower = self.searchValue.toLowerCase();
    //     let lowerName = customer.userName.toLowerCase();
    //     let lowerAccountName = customer.accountName.toLowerCase();
    //     let lowerEmail = customer.email.toLowerCase();
    //     if (searchValueLower === "") return true;
    //     return (
    //       lowerName.indexOf(searchValueLower) !== -1 ||
    //       lowerEmail.indexOf(searchValueLower) !== -1 ||
    //       lowerAccountName.indexOf(searchValueLower) !== -1 ||
    //       customer.plan === searchValueLower
    //     );
    //   });
    // },
  },
  methods: {
    LoginAs: async function (customer) {
      this.$A.SaveDataCache("admin_impersonate", this.$A.Auth);
      await this.$A.UserService.Login(
          customer.email,
          "ELCaptain:" + this.$A.Auth.apiToken.token
      );
    },
    loadPage: async function (page, perPage, filter) {
      let response = await this.$A.SuperAdminService.GetPage(page, perPage, filter);
      this.customers = response.results;
      this.totalItems = response.totalItems;
      window.$VI.$emit("page::search::value::updated", this.searchValue);
    },
    sortByMostRecent: function () {
      sort(this.customers).desc((a) => a.id);
    },
    sortByMostUsed: function () {
      sort(this.customers).desc((a) => a.totalProofs);
    },
  },
  watch: {
    currentPage: async function (page) {
      await this.loadPage(page, this.perPage, this.searchValue.toLowerCase());
    },
    searchValue: async function (searchValue) {
      await this.loadPage(this.currentPage, this.perPage, searchValue.toLowerCase());
    }
  },
};
</script>
<template>
  <div>
    <navigation/>
    <div class="container-fluid mt-50">
      <b-card class="customers-card" style="margin-top: 100px">
        <pageheader
            title="Captains Deck"
            demostyle="display: none;"
            :searchValue="searchValue"
            columns="col-md-8"
        />
        <div>
          <b-table v-if="customers.length" id="tableApprover"
                   class="rounded"
                   :bordered="true"
                   :borderless="true"
                   :items="customers"
                   :fields="fields"
                   responsive outlined bordered borderless
                   thead-class="table-bg"
                   tbody-class="table-body"
          >
            <template #head()="data">
              <span class="table-header-text">{{ data.label }}</span>
            </template>
            <template #head(company_name)="data">
              <div>{{ data.accountName }}</div>
            </template>
            <template #cell(name)="data">
              <div>{{ data.item.userName }}</div>
            </template>
            <template #cell(account_id)="data">
              {{ data.item.id }}
            </template>
            <template #cell(email)="data">
              <a>{{ data.item.email }}</a>
            </template>
            <template #cell(plan)="data">
              <a>{{ data.item.plan }}</a>
            </template>
            <template #cell(total_proofs)="data">
              <a>{{ data.item.totalProofs }}</a>
            </template>
            <template #cell(date)="data">
              <a>{{ $A.FormatDateToString(data.item.dateJoined) }}</a>
            </template>
            <template #cell(createdAt)="data">
              <a>{{ $A.FormatDateToString(data.item.dateAccountJoined) }}</a>
            </template>
            <template #cell(date_last_login)="data">
              <a>{{ $A.FormatDateToString(data.item.lastLogin) }}</a>
            </template>
            <template #cell(last_ip)="data">
              <a>{{ data.item.lastIpSeen }}</a>
            </template>
            <template #cell(impersonate)="data">
              <a @click="LoginAs(data.item)" class="view-link">Impersonate</a>
            </template>
            <template slot="custom-foot">
              <b-tr class="border ml-auto">
                <b-td colspan="12" class="pb-0" style="background-color: #F4F4F6!important;  opacity: 0.80; border-bottom: 0.2px solid #EBEBED;">
                  <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalItems"
                      aria-controls="my-table"
                      align="fill"
                      size="md"
                      page-class="customPage"
                  >
                    <template #page="{ page, active }">
                      <b v-if="active" class="text-dark">{{ page }}</b>
                      <span v-else class="customPage">{{ page }}</span>
                    </template>
                  </b-pagination>
                </b-td>
              </b-tr>
            </template>
          </b-table>
        </div>
      </b-card>
    </div>
  </div>
</template>

<style scoped>

.container-fluid {
  max-width: 2000px !important;
}

.null-item img {
  height: 100px;
  margin-bottom: 25px;
  animation: hook 5s infinite;
}

.null-item h4 {
  margin-bottom: 15px;
}

.null-item {
  list-style-type: none;
  text-align: center;
}

.customPage {
  color: #212529 !important;
}

::v-deep .customPage.page-item.active .page-link {
  background-color: white;
  border-color: lightgray;
}

::v-deep .table-body {
  background-color: white !important;
}

::v-deep .page-link {
  color: #2d2d2d;
}

@-webkit-keyframes hook {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.searchStyle {
  float: left;
}

.customPage {
  color: #212529 !important;
}

::v-deep .customPage {
  color: #212529 !important;
}

::v-deep .page-item .page-link {
  color: #019256 !important;
}

::v-deep .page-item.disabled .page-link {
  color: #6c757d !important;
}

::v-deep .customPage.page-item.active .page-link {
  background-color: #EBEBED;
  border-color: #019256;
  color: #019256;
}

::v-deep .customPage.page-item.active .page-link .customPage {
  color: #019256 !important;
}

.view-link {
  color: #019256 !important;
  cursor: pointer;
}

/deep/ .table-bg {
  background: #F4F4F6;
  opacity: 0.50;
  border-bottom: 0.2px solid #EBEBED;
}

.null-item img {
  height: 100px;
  margin-bottom: 25px;
  animation: hook 5s infinite;
}

.null-item h4 {
  margin-bottom: 15px;
}

.null-item {
  list-style-type: none;
  text-align: center;
}

.table-header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1B1B1C;
}

/deep/ .table th {
  border-bottom: 1px solid #dee2e6;
  padding-left: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: left;
}

::v-deep .customers-card .card-body {
  padding: 24px !important;
}

@media screen and (max-width: 998px) {

  ::v-deep .customers-card {
    margin-top: 0px !important;
  }
}


</style>
